import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import languageReducer from './languageReducer';


const rootReducer = combineReducers({
  language: languageReducer,
  form: formReducer
})


export default rootReducer;