import produce from 'immer';
import {
  ActionType,
  LanguageStateType,
} from './types';
import {
  TO_ENGLISH,
  TO_THAI,
} from 'src/actions/languageActions';


const initialState = {
  language: "THAI"
}


const languageReducer = (
  state:LanguageStateType=initialState,
  action:ActionType
) => {
  switch (action.type) {
    case TO_ENGLISH: {
      return produce(state, (draft) => {
        draft.language = "ENG"
      })
    }

    case TO_THAI: {
      return produce(state, (draft) => {
        draft.language = "THAI"
      })
    }

    default: {
      return state;
    }
  }
};

export default languageReducer;