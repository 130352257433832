import React, {
  lazy,
} from 'react';
import {
  Redirect,
} from 'react-router-dom';
import RouteProp from './interface';
import MainLayout from 'src/layouts/MainLayout';


const routesConfig:Array<RouteProp> = [
  {
    path:'*',
    layout: MainLayout,
    routes:[
      {
        exact: true,
        path: '/',
        component: lazy(() => import ('src/views/HomePage'))
      },
      {
        exact: true,
        path: '/about',
        component: lazy(() => import ('src/views/AboutPage'))
      },
      {
        exact: true,
        path: '/products',
        component: lazy(() => import ('src/views/ProductsPage'))
      },
      {
        exact: true,
        path: '/services',
        component: lazy(() => import ('src/views/ServicesPage'))
      },
      {
        exact: true,
        path: '/events',
        component: lazy(() => import ('src/views/EventsPage'))
      },
      {
        exact: true,
        path: '/contact',
        component: lazy(() => import ('src/views/ContactUsPage'))
      },
    ]
  },
  {
    component: () => <Redirect to="/" />
  }
];

export default routesConfig;