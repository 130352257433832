import React from 'react';
import {
  Button,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  title: {
    marginRight: 'auto'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  }
}));


interface NavItemProp {
  href?: string
  title: string
}


const NavItem: React.FC<NavItemProp> =({title,href,}) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.buttonLeaf}
      href={href}
    >
      <span className={classes.title}>
        {title}
      </span>
    </Button>
  )
}

export default NavItem;





