import React from 'react';
import ReactDOM from 'react-dom';
import "./index.css";
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SettingsProvider } from 'src/context/SettingsContext';
import { configureStore } from 'src/store';
import { restoreSettings } from 'src/utils/settings';


const store = configureStore();
const settings = restoreSettings();



ReactDOM.render(
    <Provider store={store}>
      <SettingsProvider settings={settings}>
        <App />
      </SettingsProvider>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
