import React from 'react';
import {
  List,
} from '@material-ui/core';
import reduceChildRoutes from './reduceChildRoutes';
import {ConfigProp} from '../interfaces'


interface renderNavItemsProp {
  items: Array<ConfigProp>
  language: string
}


const renderNavItems: React.FC<renderNavItemsProp> = ({items, language}) => {

  return (
    <List disablePadding>
      {items.reduce(
        (acc:Array<any>, item:ConfigProp) => reduceChildRoutes(
          language,
          acc, 
          item,),
        []
      )}
    </List>
  );
}

export default renderNavItems;