import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import theme from 'src/theme';
import {ThemeProvider} from '@material-ui/core';
import Routes from 'src/Routes';
import FacebookMessenger from 'src/components/FacebookMessenger';


const history = createBrowserHistory();


const App:React.FC = (props)=> {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Routes/>
      </Router>
      <FacebookMessenger/>
    </ThemeProvider>
  );
}

export default App;
