import React, {
  createContext,
  useState,
} from 'react';
import _ from 'lodash';
import {storeSettings} from 'src/utils/settings';
import {
  SettingProp,
  SettingContextProp,
  SettingProviderProp
} from './types';


const defaultSettings:SettingProp = {
  language: 'TH',
};


const SettingsContext:React.Context<SettingContextProp> = createContext({
  settings:defaultSettings, 
  saveSettings:null
});


export const SettingsProvider:React.FC<SettingProviderProp> = ({settings, children}) => {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);

  const handleSaveSettings = (updateSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updateSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
