import React from 'react';
import {
  Box
} from '@material-ui/core';
import { socialConfig } from './config';


const SocialItems: React.FC = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
    >
      {
        socialConfig.map((item =>(
          <a
            key={item.key}
            href={item.href}
            style={{
              marginLeft:"16px",
              marginRight:"16px"
            }}
          >
            {item.icon}
          </a>
        )))
      }
    </Box>
  )
}

export default SocialItems;