import React, {
  Suspense,
  Fragment,
} from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import RouteProp from './interface';
import routesConfig from './config';
import LoadingScreen from 'src/components/LoadingScreen';


const renderRoutes = (routes:Array<RouteProp>) => (routes ? (
  <Suspense fallback={<LoadingScreen/>}>
    <Switch>
      {routes.map((route,i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Fragment>
                <Layout>
                  {
                    route.routes
                      ? renderRoutes(route.routes)
                      : <Component {...props}/>
                  }
                </Layout>
              </Fragment>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
): null);


const Routes:React.FC = () => {
  return renderRoutes(routesConfig);
}

export default Routes;