import React from 'react';


const Logo = () => {
  return (
    <img
      alt="Logo"
      src="/static/logo.svg"

    />
  );
}

export default Logo;