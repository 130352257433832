import React from 'react';
import { SvgIcon } from '@material-ui/core'
import Facebook from '@material-ui/icons/Facebook';
import {ReactComponent as Shopee} from 'src/assets/img/Shopee.svg';
import {ReactComponent as Lazada} from 'src/assets/img/Lazada.svg';
import {ReactComponent as Line} from 'src/assets/img/Line.svg';
import { ConfigProp } from './interfaces';

const navConfig: Array<ConfigProp> = [
  {
    key: "home",
    href:'/',
    title:{
      TH:'หน้าแรก',
      ENG:'Home',
    },
  },
  {
    key: "about",
    href:'/about',
    title:{
      TH:'เกี่ยวกับเรา',
      ENG:'About Us',
    },
  },
  {
    key: "products",
    href:'/products',
    title:{
      TH:'ผลิตภัณฑ์ทั้งหมด',
      ENG:'Our Products',
    },
  },
  {
    key: "services",
    href:'/services',
    title:{
      TH:'โรงอบเมล็ดพันธุ์',
      ENG:'Our Services',
    },
  },
  {
    key: "events",
    href:'/events',
    title:{
      TH:'กิจกรรม',
      ENG:'Events',
    },
  },
  {
    key: "contact",
    href:'/contact',
    title:{
      TH:'ติดต่อเรา',
      ENG:'Contact Us',
    },
  },
]

const socialConfig = [
  {
    key:"facebook",
    icon:<Facebook style={{height:"29px", width:"29px"}}/>,
    href:"https://www.facebook.com/Agrimex-Thailand-302113733822186"
  },
  {
    key:"lazada",
    icon: <SvgIcon component={Lazada} style={{height:"24px", width:"24px"}}/>,
    href:"https://www.lazada.co.th/shop/agrimex-online-store/?spm=a2o4m.pdp.seller.1.359e24712DXRXC&itemId=1216014306&channelSource=pdp"
  },
  {
    key:"shopee",
    icon:<SvgIcon component={Shopee} style={{height:"24px", width:"24px"}}/>,
    href:"https://shopee.co.th/shop/273888922/"
  },
  {
    key:"line",
    icon:<SvgIcon component={Line} style={{height:"24px", width:"24px"}}/>,
    href:"https://lin.ee/WFeqne2"
  },
]

export  {
  navConfig,
  socialConfig
}