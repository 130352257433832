import React from 'react';
import {
  Grid,
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#01532F"
  },
  text: {
    color: "white"
  }
}));


const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container justify="center">
        <Box m={2}>
          <Typography
            variant="body2"
            className={classes.text}
          >
            Copyright 2021 - All rights reserved Agrimex Thailand
          </Typography>
        </Box>
      </Grid>
    </Box>
  )

}

export default Footer;