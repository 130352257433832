export const TO_ENGLISH = '@language/to-english';
export const TO_THAI = '@language/to-thai';


export const toEnglish = () => {
  return (dispatch:any) => dispatch({
    type: TO_ENGLISH,
  });
}


export const toThai = () => {
  return (dispatch:any) => dispatch({
    type: TO_THAI,
  });
}