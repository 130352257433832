import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  makeStyles,
} from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';
import renderNavItems from './renderNavItems';
import {navConfig} from '../config'
import SocialItems from '../SocialItems';


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  divider:{
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "24px"
  }
}));


interface NavBarProp {
  openMobile: boolean
  onMobileClose: React.MouseEventHandler<HTMLButtonElement>
}


const NavBar: React.FC<NavBarProp> = ({ openMobile, onMobileClose}) => {
  const classes = useStyles();
  const { settings } = useSettings();

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {renderNavItems({ 
            items: navConfig, 
            language:settings.language 
          })}
        </Box>
        <Divider className={classes.divider}/>
        <SocialItems/>
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="right"
          classes={{ paper: classes.mobileDrawer }}
          open={openMobile}
          onClose={onMobileClose}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

export default NavBar;