import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';


export default function FacebookMessenger() {
  return (
    <MessengerCustomerChat
      pageId="302113733822186"
      appId="721751608384143"
    />
  )
}