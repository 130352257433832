import { 
  colors, 
  createMuiTheme, 
  responsiveFontSizes 
} from '@material-ui/core';

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#388e3c',
      main: '#2e7d32',
      dark: '#1b5e20',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fbc02d',
      main: '#f9a825',
      dark: '#f57f17',
      contrastText: '#000',
    },
    background:{
      default: colors.common.white,
      paper: colors.common.white
    }
    
  },
  typography: {
    fontFamily: 'Kanit',
  },
});

theme = responsiveFontSizes(theme);


export default theme;