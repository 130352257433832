import React from 'react';
import {
  Button,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    marginLeft: "8px",
    marginRight: "8px",
    color:theme.palette.text.secondary
  },
  title: {
    marginRight: 'auto'
  },
}));


interface MenuItemProp {
  href?: string
  title: string
}


const MenuItem: React.FC<MenuItemProp> =({title,href,}) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.item}
      href={href}
    >
      <span className={classes.title}>
        {title}
      </span>
    </Button>
  )
}

export default MenuItem;





