import React from 'react';
import NavItem from './NavItem';
import {ConfigProp} from '../interfaces'


const reduceChildRoutes = (
  language:string,
  acc:Array<any>,
  item:ConfigProp,
) => {
  
  // Thai case
  if (language === "TH") {
    acc.push(
      <NavItem
        href={item.href}
        key={item.key}
        title={item.title.TH}
      />
    );

  // English case
  } else{
    acc.push(
      <NavItem
        href={item.href}
        key={item.key}
        title={item.title.ENG}
      />
    );
  }
  return acc;
}

export default reduceChildRoutes;