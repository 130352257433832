import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Divider,
  Hidden,
  IconButton,
  MenuItem,
  SvgIcon,
  Select,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import useSettings from 'src/hooks/useSettings';
import TopItem from './TopItem';
import {navConfig} from '../config';
import SocialItems from '../SocialItems';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "inherit",
    zIndex: theme.zIndex.drawer + 100,
  },
  toolbar: {
    minHeight: 64
  },
  link: {
    fontFamily:"Sukhumvit",
    color: theme.palette.text.secondary,
    textDecoration:'none',
    fontWeight: theme.typography.fontWeightBold,
    '& + &': {
      marginLeft: theme.spacing(4)
    }
  },
  linkIcon: {
    marginLeft:"16px",
    marginRight:"16px",
    marginTop: "7px"
  },
  divider:{
    marginTop:"8px",
    marginBottom:"8px",
    marginLeft:"16px",
    marginRight:"16px"
  },
  selectLanguage:{
    color:theme.palette.text.secondary,
    border: "0px",
    marginLeft:"16px",
    marginRight:"16px"
  }
}));


interface TopBarProp {
  onMobileNavOpen: React.MouseEventHandler<HTMLButtonElement>
}


const TopBar:React.FC<TopBarProp> = ({onMobileNavOpen}) => {
  const classes = useStyles();
  const { settings, saveSettings } = useSettings();
  
  const handleSelectLanguangeChange = (event: React.ChangeEvent<{ value: unknown}>) => {
    saveSettings({
      language: event.target.value,
    });
  }

  const SelectLanguage = () => {
    return  (
    <Select
      disableUnderline
      value={settings.language}
      className={classes.selectLanguage}
      onChange={handleSelectLanguangeChange}
    >
      <MenuItem value={"TH"}> ภาษาไทย </MenuItem>
      <MenuItem value={"ENG"}> English </MenuItem>
    </Select>)
  }

  return (
    <AppBar
      className={classes.root}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo/>
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden lgUp>
          <SelectLanguage/>
          <IconButton
            color="primary"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon/>
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          {
            navConfig.map((item => (
              <TopItem 
                key={item.key}
                title={item.title[settings.language]}
                href={item.href}
              />
            )))
          }
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <SocialItems/>
          <Divider orientation="vertical" flexItem className={classes.divider}/>
          <SelectLanguage/>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}
export default TopBar;